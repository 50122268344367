import { ImgixGatsbyImage } from "@imgix/gatsby"
import { graphql } from "gatsby"
import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import ChangeHeaderInView from "../components/changeHeaderinView"
import HeroBanner from "../components/heroBanner"
import LatestNews from "../components/latestNews"
import Layout from "../components/layout"
import Link from "../components/link"
import PageNavigation from "../components/pageNavigation"
import Seo from "../components/seo"
import Spacer from "../components/spacer"
import { COLOR_STATES } from "../context/menuContext"
import { useLanguage } from "../hooks/useLanguage"
import { copyUrl, linkedIn, twitter } from "../utils/share"

function Image({ node, src, ...props }) {
    return <ImgixGatsbyImage layout='constrained' aspectRatio={4 / 3} src={src} className='mt40 mb40' />
}

export default function News({ data }) {
    var post = data.news
    const misc = data.misc
    const { switchLanguage } = useLanguage()
    const [copied, setCopied] = useState(false)

    const copyPageUrl = () => {
        copyUrl(typeof window !== "undefined" && window.location.href)
        setCopied(true)
    }

    const share = fn => {
        window.open(typeof window !== "undefined" && fn(window.location.href))
    }

    return (
        <Layout>
            <Seo title={post.name} />
            <HeroBanner title={switchLanguage(post.nameJp, post.name)} image={post.image} />
            <ChangeHeaderInView threshold={0.1} color={COLOR_STATES.DARK}>
                <PageNavigation />

                <div className='pl60 pr60 pt20 text-grey m-p20'>
                    <Link className='back-link' to='/news'>
                        {switchLanguage(misc.goBackJp, misc.goBack)}
                    </Link>
                </div>

                <div className='title-text p60 max-1200 ma h3 m-p20'>{switchLanguage(post.introductionJp, post.introduction)}</div>

                <ReactMarkdown components={{ img: Image }} className='post--content lh140 text-grey p60 max-800 ma m-p20 m-mb40'>
                    {switchLanguage(post.postContentJp, post.postContent)}
                </ReactMarkdown>

                <Spacer className='m-hide' />

                <div className='pl60 pr60 max-1200 ma m-pl20 m-pr20 '>
                    <div className=' bt1 pt60 pb60 m-pt20 ma bb1'>
                        <p className='h3 m0'>{switchLanguage(misc.titleJp, misc.title)}</p>
                        <p className='h3 m0 text-grey'>{switchLanguage(misc.subtitleJp, misc.subtitle)}</p>
                    </div>
                    <div className='pt20 pb20 ma flex'>
                        <div className='flex align-center'>
                            {/* TODO rewrite */}
                            <button onClick={() => share(twitter)} aria-label='share to twitter' rel='noreferrer' className='social-icon twitter mr10' />
                            <button onClick={() => share(linkedIn)} aria-label='share to facebook' rel='noreferrer' className='social-icon linkedin mr10' />
                            <span className='text-grey'>{switchLanguage(misc.shareTextJp, misc.shareText)}</span>
                        </div>
                        <button className='copy--link flex align-center mla' onClick={copyPageUrl}>
                            <div aria-label='copy url' className='social-icon copy mr10' />
                            <span className='text-grey'>{!copied ? switchLanguage(misc.copyTextJp, misc.copyText) : switchLanguage(misc.copiedJp, misc.copied)}</span>
                        </button>
                    </div>
                </div>
                <Spacer />
                <LatestNews className='pb160' title={switchLanguage(misc.moreTitleJp, misc.moreTitle)} news={data.more.nodes} />
            </ChangeHeaderInView>
        </Layout>
    )
}

export const query = graphql`
    query NewsPage($id: String) {
        pageData: datoCmsNewsPage {
            subtitle
            subtitleJp
        }
        news: datoCmsNews(id: { eq: $id }) {
            name
            nameJp
            image {
                gatsbyImageData
            }
            introduction
            introductionJp
            postContent
            postContentJp
        }
        misc: datoCmsNewsMisc {
            title
            titleJp
            subtitle
            subtitleJp
            shareText
            shareTextJp
            moreTitle
            moreTitleJp
            goBack
            goBackJp
            copyText
            copyTextJp
            copied
            copiedJp
        }
        more: allDatoCmsNews(limit: 3, filter: { id: { ne: $id } }) {
            nodes {
                ...NewsCard
            }
        }
    }
`
