import React from 'react'
import { P } from './fonts'
import Svg from 'react-inlinesvg';

export default function IconNText({ icon, children, styled = false, imgClassName, ...props }) {
    return (
        <div className="flex align-centers m-mb10 m-mt10 mb5 ">
            {styled ?
                <Svg src={icon.url} className={"mr10 max-25  " + imgClassName} /> :
                <img alt='' src={icon?.url} className={"mr10 max-25 " + imgClassName} />
            }
            <P {...props} >{children}</P>
        </div>
    )
}
