
export const twitter = (url, text) => {
    const twitterTemplate = `http://twitter.com/share?text=${text}&url=${url}`
    return twitterTemplate
}

export const linkedIn = (url) => {
    const linkedInTemplate = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
    return linkedInTemplate
}


export const copyUrl = (url) => {
   typeof navigator !== 'undefined' && navigator.clipboard.writeText(url)
}