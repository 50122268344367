import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import { useButtonText } from "../hooks/useButtonText"
import { useLanguage } from "../hooks/useLanguage"
import time from "../images/time.svg"
import minsToRead from "../utils/minsToRead"
import { P } from "./fonts"
import IconNText from "./iconNText"
import Link from "./link"
import ReadMore from "./readMore"
const NewsCard = ({ post, className }) => {
    const { switchLanguage } = useLanguage()
    const btnText = useButtonText()
    return (
        <Link to={`/news/${post.slug}`} className={"link single--article hover-image--zoom m-mb20 " + className}>
            <div className='flex flex-col h-100'>
                <div className='thumbnail ratio-2-1 overflow-hidden br-10 mb20'>
                    <GatsbyImage className='bg-image' alt='Post Image' image={post.image?.gatsbyImageData} />
                </div>
                <div className='flex-grow-1'>
                    <IconNText icon={post?.category?.image} className='text-grey'>
                        {switchLanguage(post.category?.nameJp, post.category?.name)}
                    </IconNText>
                    <p className='m0 h3 m-h3'>{switchLanguage(post.nameJp, post.name)}</p>
                    <div className='spacer half' />
                </div>
                <div>
                    <div className='w-100 h-1 overflow-hidden bg-grey-15 '>
                        <div className='bg-green w-100 h-100 transform-left-right' />
                    </div>
                    <div className='pt15 flex justify-between '>
                        <ReadMore as={P} className='not-hover' linkAs={"div"} children={switchLanguage(btnText.readTitleJp, btnText.readTitle)} />
                        <div className='flex text-grey time'>
                            <IconNText icon={{ url: time }}>
                                {minsToRead(post.postContent?.split(" ").length)} {switchLanguage("分", "min")}
                            </IconNText>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}
export default NewsCard

export const query = graphql`
    fragment NewsCard on DatoCmsNews {
        slug
        category {
            name
            nameJp
            image {
                url
            }
        }
        image {
            gatsbyImageData
        }
        postContent
        name
        nameJp
    }
`
