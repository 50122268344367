import * as React from "react"
import Button from "../components/button"


const PageNavigation = ({ background, className }) => {
  return (
    <div className={'page--navigation  z-3 pt20 pb20 m-hide '+className} style={{ background }}>
      <Button text='book demo' className="hide" />
    </div >
  )
}
export default PageNavigation
