import * as React from "react"
import NewsCard from "./newsCard"

const LatestNews = ({ news, title, className }) => {
    const allNews = news.map((post, index) => <NewsCard post={post} key={index} />)
    return (
        <div className={"latest--news p60 max-1600 ma m-p20 m-pt0 m-bg-grey border-box m-pt40 " + className}>
            <p className='m0 h3 mb30 '>{title}</p>
            <div className='grid grid-3 gap-20 m-grid-1 t-grid-2'>{allNews}</div>
        </div>
    )
}
export default LatestNews
